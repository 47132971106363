import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  formData: FormData = new FormData(); 
  formType: string = '';
  private formTypeSubject: BehaviorSubject<string> = new BehaviorSubject<string>('defaultFormType');

  constructor() { }

  saveFormData(data: any) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        this.formData.append(key, data[key]);
      }
    }
  }

  getFormData() {
    return this.formData;
  }
  
  clearFormData() {
    this.formData = new FormData();
  }

  setFormType(type: string): void {
    this.formTypeSubject.next(type);
}

getFormType(): BehaviorSubject<string> {
    return this.formTypeSubject;
}
}
